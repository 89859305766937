import CSS from './new-features.module.css'


const STRUCTURE_DATA = [
    {
        date: "Tuesday 17 June",
        timeslots: [
            {
                startTime: "11:00",
                endTime: "14:00",
                sessions: [
                    {
                        title: "Rolling Registration, Lunch and Exhibition"
                    }
                ]
            },
            {
                startTime: "14:00",
                endTime: "17:00",
                sessions: [
                    {
                        title: "Exhibition and Networking"
                    },
                    {
                        title: "Interactive Sessions on a variety of topics"
                    }
                ]
            },
            {
                startTime: "17:00",
                endTime: "18:00",
                sessions: [
                    {
                        title: "Speed Networking"
                    }
                ]
            },
            {
                startTime: "18:00",
                endTime: "21:00",
                sessions: [
                    {
                        title: "Evening Reception"
                    }
                ]
            }
        ]
    },
    {
        date: "Wednesday 18 June",
        timeslots: [
            {
                startTime: "09:00",
                endTime: "13:00",
                sessions: [
                    {
                        title: "Main Plenary Conference at CTICC",
                        description: "*Exhibition will be closed during this time",
                    }
                ]
            },
            {
                startTime: "13:00",
                endTime: "14:30",
                sessions: [
                    {
                        title: "Lunch, Exhibition and Networking"
                    }
                ]
            },
            {
                startTime: "14:30",
                endTime: "18:00",
                sessions: [
                    {
                        title: "Exhibition and Networking"
                    },
                    {
                        title: "Interactive Sessions on a variety of topics"
                    }
                ]
            },
            {
                startTime: "18:00",
                endTime: "21:00",
                sessions: [
                    {
                        title: "Evening Reception"
                    }
                ]
            }
        ]
    },
    {
        date: "Thursday 19 June",
        timeslots: [
            {
                startTime: "07:30",
                endTime: "08:00",
                sessions: [
                    {
                        title: "Charity Run"
                    }
                ]
            },
            {
                startTime: "09:00",
                endTime: "14:00",
                sessions: [
                    {
                        title: "Exhibition and Networking"
                    },
                    {
                        title: "Interactive Sessions on a variety of topics"
                    }
                ]
            },
            {
                startTime: "14:00",
                endTime: "15:00",
                sessions: [
                    {
                        title: "Lunch, Exhibition and Networking",
                    }
                ]
            },
            {
                startTime: "15:00",
                endTime: "17:00",
                sessions: [
                    {
                        title: "Social Site Visits"
                    }
                ]
            }
        ]
    }
]

export const NewFeatures = () => {
    return(
        <section className={`${CSS.layout} block`}>

          
                <div className={`${CSS.body} container pr-md formatted`}>
                    <p className="fs-0 fw-600 c-primary lh-1">New for AHIF&nbsp;2025!</p>
                    <p className="fs-4 pt-sm"><strong>AHIF is best place to network, catch up with existing clients and make new business contacts.</strong> </p>
                    
                    <p className="mt-xs">With networking rated as the most important element for AHIF attendees, our agenda is structured so you can build your own AHIF itinerary. We will have the exhibition open throughout the event. Complimenting this with a variety of interactive content sessions allowing you to attend relevant roundtable, panel discussions, in-depth workshops, country showcases, project opportunities & short keynote presentations. All these sessions will happen at staggered intervals in dedicated spaces throughout the venue.</p>
                    
                    <p>For one morning, however, the exhibition will pause, giving all attendees the opportunity to benefit from high-level economic updates, pipeline insights, and market reports delivered by key industry leaders, ensuring everyone stays informed on critical industry trends.  *This element will take place across the road at the CTICC.</p>
                    
                    <p>AHIF remains a comprehensive solution for accelerating growth on the continent. It provides the platform to demonstrate thought leadership, raise awareness and engage in efficient, relevant networking.</p>
                    
                    <p>We look forward to welcoming you to AHIF 2025 to <strong>Connect</strong> with key players in the industry, <strong>Invest</strong> in new opportunities and <strong>Transform</strong> the hospitality landscape.</p>
                </div>

                <div className={CSS.agenda}>
                    <p className="fs-3 fw-600 c-primary mb-sm">Overall event structure for 2015</p>
                    {
                        STRUCTURE_DATA.map( day => (
                            <section key={day.date} className={CSS.day}>
                                <div className={`${CSS.dayTitleBar} fs-5 fw-500 c-white bg-dark`}>{ day.date }</div>
                                {
                                    day.timeslots?.map( timeslot => (
                                        <div key={timeslot.startTime} className={CSS.timeslot}>
                                            <aside><p className="fs-sm fw-500 c-primary">{ timeslot.startTime } - { timeslot.endTime }</p></aside>
                                            <main>
                                                {
                                                    timeslot.sessions?.map( session => (
                                                        <div className={CSS.session}>
                                                            <p className={CSS.sessionTitle}>{ session.title }</p>
                                                            {
                                                                session.description &&
                                                                <p className="fs-sm mt-xxs">{ session.description }</p>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </main>
                                        </div>
                                    ))
                                }
                            </section>
                        ))
                    }
                </div>



            
        </section>
    )
}