import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import Head from "next/head"

import { QuerySEO } from "../graphql/queries"

import handleSeomaticMeta from "../lib/seomatic"


import { HeroBrand } from "../fuselage/blocks/hero-brand/hero-brand"
import { ImpactFigures, ImpactFigure, ImpactFigureSpacer } from "../fuselage/blocks/impact-figures/impact-figures"
import { Video } from "../fuselage/blocks/video/video"
import { SpeakerCarousel } from '../fuselage/blocks/speaker-carousel/speaker-carousel'
import { SpeakerCard } from "../fuselage/components/speaker-card/speaker-card"

import { BrandSection } from "../fuselage/blocks/brand-section/brand-section"
import { Quote } from '../fuselage/components/quote/quote'

import { HeaderShim } from "../fuselage/global/header/header"
import { LogoBoxCarousel } from "../fuselage/components/logo-box-carousel/logo-box-carousel"
import { SponsorGrid } from "../fuselage/layout/homepage/sponsor-grid/sponsor-grid"
import { FeaturedContent } from "../fuselage/layout/homepage/featured-content/featured-content"
import { PopOver } from '../fuselage/components/popover/popover'
import { ArrowButton } from "../fuselage/components/arrow-button/arrow-button"

import { MontageGrid, MontageImage, MontageBox, MontageSpacer, MontageGraphic } from "../fuselage/layout/montage/montage"
import { NewFeatures } from "../fuselage/layout/homepage/new-features/new-features"

export default function Homepage ({ seomatic, entry, press, partnerGroups }) {

    // console.log('seomatic:', seomatic)
    // console.log('entry:', entry)
    // console.log('entry.speakers:', entry.speakers[0]?.speakers)
    // console.log('partnerGroups:', partnerGroups)
	// console.log('press:', press)



    return (
        <div style={{ width: `100%`, overflow: `hidden` }}>
            <Head>
                { handleSeomaticMeta( seomatic ) }
            </Head>

            <HeaderShim />


            <HeroBrand>
                <p className="fs-4">The Africa Hospitality Investment Forum (AHIF) is the leading hospitality investment forum on the African continent. The event connects business leaders to enable deals that drive investment and development into tourism and hotel infrastructure.</p>
            </HeroBrand>


            <NewFeatures />


            <ImpactFigures theme={'secondary'}>
                <div className="sideRule pb-sm" style={{ gridColumn: `auto / span 2` }}>
                    <p className="fs-1 fw-700 c-primary">AHIF 2024 in numbers</p>
                </div>
                <ImpactFigureSpacer/>
       
                <ImpactFigure value="450+" label="attendees" />
                <ImpactFigure value="72%" label="international delegates" />
                <ImpactFigureSpacer/>
                <ImpactFigureSpacer/>
                <ImpactFigure value="40+" label="countries represented" />
                <ImpactFigure value="16%" label="investors" />
                <ImpactFigure value="90+" label="leading industry speakers" />
                <ImpactFigure value="3 DAYS" label="of business-critical networking" />
                <ImpactFigure value="50+" label="sponsoring companies" />
            </ImpactFigures>


            {/* <BrandSection topBar={{ graphic: true, position: 'right' }}>
                <div className="maxw-55">
                    <Quote avatar={false} quote={"We are looking forward to hosting a world-class event that will help us to promote and showcase Namibia's potential and value proposition as an investment destination, while at the same time serving as a nexus where investors, industry leaders and governments connect, network and forge mutually beneficial partnerships to unlock tangible opportunities that will catalyse further growth and development in our tourism industry."} name={'Nangula Nelulu Uaandja'} title={'Chairperson and CEO: Namibia Investment Promotion and Development Board'} decal={'fs-4'} />
                </div>
            </BrandSection> */}




            <Video videoUrl={'https://www.youtube.com/watch?v=s5pKoHX83Nk'} theme={'bg-primary'} bgUpper={'secondary'} bgLower={'bg-wash'} >
                <p className="fs-1 fw-600">AHIF 2024 Video Highlights</p>
            </Video>


            <SpeakerCarousel heading={'Featured Speakers'} ctaLink={'/speakers'} ctaLabel={'See all speakers'}>
                {
                    entry.speakers[0]?.speakers.map( speaker => (
                        <SpeakerCard 
                            key={speaker.id}
                            url={`/speakers/profile/${speaker.slug}`}
                            photoUrl={speaker.avatar[0].url}
                            fullName={speaker.title}
                            jobTitle={speaker.positions[0].jobTitle}
                            company={speaker.positions[0].organisation}
                        />
                    ))
                }
            </SpeakerCarousel>


            <SponsorGrid>
                { 
                    partnerGroups.length &&  
                    partnerGroups.map( group => (
                        <LogoBoxCarousel 
                            key={ group.id }
                            heading={ group.title } 
                            sponsors={ group.partners } 
                        />
                    )) 
                }
            </SponsorGrid>


            <MontageGrid>

                <MontageBox span={2}>
                    <div className="block">
                        <p className={`fs-1 fw-600 c-white`}>AHIF 2024 in photos</p>
                    </div>
                </MontageBox>

                <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-01.jpg`} width={650} height={650}/>
                
                <MontageSpacer backgroundColor={'var(--wash)'}/>

                <MontageGraphic />

                <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-04.jpg`} width={650} height={650}/>
                
                <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-02.jpg`} width={1250} height={890} span={2}/>

                <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-03.jpg`} width={1435} height={1050} span={2}/>

                <MontageGraphic />

                <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-05.jpg`} width={650} height={650}/>
                
                <MontageSpacer/>

                <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-07.jpg`} width={650} height={650}/>

                <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-06.jpg`} width={1435} height={1050} span={2}/>

                <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-08.jpg`} width={1435} height={1050} span={2}/>

                <MontageImage  src={`/assets/img/homepage/gallery-2024/ahif-2024-09.jpg`} width={650} height={650}/>

                <MontageSpacer backgroundColor={`var(--wash)`} span={2} />

                <MontageBox span={2}>
                    <div className="block">
                        <p>
                            <ArrowButton 
                                href={`https://www.flickr.com/photos/benchevents/albums/72177720318292463`} 
                                target={`_blank`} 
                                theme={{ arrow: `white`, border: `white`, label: `white`}}
                            >See more photos</ArrowButton>
                        </p>
                    </div>
                </MontageBox>

            </MontageGrid>


            <FeaturedContent posts={press} />


            {/* <PopOver/> */}

        </div>
    )
}



export async function getStaticProps({ preview, previewData }) {

    const data = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query Homepage {
				entry(site: "${process.env.SITE_HANDLE}", id: "15757") {
					... on ${process.env.SITE_HANDLE}Homepage_${process.env.SITE_HANDLE}Homepage_Entry {
						${QuerySEO}
                        
                        speakers {
                            ... on speakers_heading_BlockType {
                                id
                                heading
                            }
                            ... on speakers_speakers_BlockType {
                                id
                                speakers {
                                    ... on profiles_profile_Entry {
                                        id
                                        title
                                        slug
                                        avatar {
                                            id
                                            url
                                            width
                                            height
                                        }
                                        positions {
                                            ... on positions_BlockType {
                                                id
                                                jobTitle
                                                organisation
                                            }
                                        }
                                    }
                                }
                            }
                        }
					}
				}
			}
        `
    })
    const pageData = data
    
    const partners = await craftApolloClient( preview, previewData ).query({
        query: gql`
            query Partners {
                entries(site: "${process.env.SITE_HANDLE}", section: "partnerGroups") {
                    ... on partnerGroups_default_Entry {
                        id
                        title
                        partners {
                            ... on partners_partner_Entry {
                                id
                                slug
                                title
                                website
                                partnerLogo {
                                    id
                                    url
                                    width
                                    height
                                }
                            }
                        }
                    }
                }
            }
        `
    })
    const partnerData = partners
    
    
	const pressQuery = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query PressRoom {
				entries(
                    site: "${process.env.SITE_HANDLE}", 
                    section: "contentLibrary", 
                    relatedTo: "7702", 
                    limit: 3
                ) {
					... on contentLibrary_article_Entry {
						id
						slug
						title
						postDate
						excerpt
						contentLibraryHero {
							id
							url
							width
							height
						}
						contentTypes {
							... on contentLibrary_Category {
								id
								title
								slug
							}
						}
					}
				}
			}
        `
    })
    const pressData = pressQuery

    // console.dir(pageData)
    // console.log(pageData.data.entry.speakers[0].speakers)


    return { 
		props: { 
            seomatic: pageData.data.entry.seomatic,
            entry: pageData.data.entry,
            partnerGroups: partnerData.data.entries,
			press: pressData.data.entries,
		}
	}
}
